<template>
  <!-- The :key prop on the listing component forces a re-render on each route change,
  preventing any component lifecycle bug (ex: not having to manually trigger a
  filter reset on each route change) -->
  <listing
    :key="$route.meta.type"
    :type-name="$route.meta.type"
    :disable-edit-item="!canEditModule(typeId)"
    :disable-add-item="!canAddModule(typeId)"
    :disable-delete-item="!canDeleteModule(typeId)"
    :disable-export-item="!canExportModule(typeId)"
    cached-filters
  />
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import Listing from '../../components/Listing/Listing';

export default {
  components: {
    Listing,
  },
  computed: {
    ...mapGetters('auth', ['canEditModule', 'canAddModule', 'canDeleteModule', 'canExportModule']),
    /* Used to get permissions
    * Return submodule Id if it exists, or type id if this is a regular module
    * @return {String}, the id of module or submodule for fetching permissions
    */
    typeId () {
      return this.submoduleId ? this.submoduleId : this.$route.meta.type;
    },
    /* In case this is a submodule, we must find the submoduleId
    *
    * @returns {String/null} The id if it is a submodule, or null if it isn't
    */
    submoduleId () {
      let submoduleId = null;
      const types = get(this.$xms, 'config.modules.crud.types', []);
      types.find(type => {
        const submodule = type.submodules !== undefined
          ? type.submodules.find(sub => sub.path === this.$route.path)
          : undefined;
        if (submodule !== undefined) {
          submoduleId = submodule.id;
        }
        return submoduleId;
      });
      return submoduleId;
    },
  },
};
</script>
